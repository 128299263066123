import { mapState, mapActions, mapMutations } from 'vuex'

export const userManagementComputed = {
  ...mapState('userManagement', {
    users: (state) => state.users,
    photos: (state) => state.photos,
    userDetails: (state) => state.userDetails,
    updatePhoto: (state) => state.updatePhoto,
    loading: (state) => state.loading,
    key: (state) => state.componentKey,
  }),
  ...mapState('enterprise', {
    enterpriseId: (state) => state.currentEnterprise.id,
  }),
  ...mapState('app', {
    currentModal: (state) => state.currentModal,
  }),
  ...mapState('general', {
    storedBaseApiUrl: (state) => state.base_api_url,
  }),
}

export const userManagementMethods = {
  ...mapActions('userManagement', [
    'getUser',
    'getUsers',
    'addUser',
    'deleteUser',
    'editUser',
    'setLoading',
    'passwordResetEmail',
  ]),
}

export const userManagementMutations = {
  ...mapMutations('app', ['SET_CURRENT_MODAL']),
  ...mapMutations('userManagement', ['SET_USER_DETAILS']),
  ...mapMutations('userManagement', ['SET_UPDATE_PHOTO']),
}
