<script>
import { addAdministratorsComputed } from '@state/helpers'
import { userManagementMethods } from '@state/helpers/userManagement'
import {
  emailValidation,
  maxLengthValidation,
  minLengthValidation,
  requiredValidation,
} from '@utils/inputValidation'
export default {
  props: {
    bus: {
      type: [String, Object],
      default: 'div',
    },
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      rules: {
        required: requiredValidation,
        minLength: minLengthValidation,
        maxLength: maxLengthValidation,
        email: emailValidation,
      },
    }
  },
  computed: {
    ...addAdministratorsComputed,
  },
  mounted: function () {
    this.bus.$on('validateForm', this.validateForm)
  },
  beforeDestroy() {
    this.bus.$off('validateForm')
  },
  methods: {
    ...userManagementMethods,
    validateForm() {
      if (this.$refs.email) {
        const invalidFields = []
        Object.keys(this.$refs).forEach((field) => {
          switch (field) {
            case 'email':
              if (
                this.rules.required(this.$refs.email.value) !== true ||
                this.rules.email(this.$refs.email.value) !== true
              ) {
                invalidFields.push(field)
              }
              break
            default:
              if (
                this.rules.required(this.$refs[field].value) !== true ||
                this.rules.minLength(this.$refs[field].value) !== true ||
                this.rules.maxLength(this.$refs[field].value) !== true
              ) {
                invalidFields.push(field)
              }
          }
        })
        if (!invalidFields.length) {
          this.submitForm()
        } else {
          invalidFields.forEach((field) => {
            this.$refs[field].error = true // Make invalid inputs jiggle
          })
        }
      }
    },
    submitForm() {
      const payload = {
        disabled: true,
        enterprise_id: this.enterpriseId,
        first_name: this.firstName,
        last_name: this.lastName,
        password: 'Password1',
        roles: ['ENTERPRISE_ADMIN'],
        username: this.email,
      }
      this.closeFn()
      this.addUser(payload)
    },
  },
}
</script>

<template>
  <div class="addUser">
    <h4>{{ $t('GENERAL.PERSONAL_DETAILS') }}</h4>
    <v-form>
      <v-text-field
        ref="firstName"
        v-model="firstName"
        :rules="[rules.required, rules.minLength, rules.maxLength]"
        outlined
        :label="$t('LABEL_FIRST_NAME')"
      ></v-text-field>
      <v-text-field
        ref="lastName"
        v-model="lastName"
        :rules="[rules.required, rules.minLength, rules.maxLength]"
        outlined
        :label="$t('LABEL_LAST_NAME')"
      ></v-text-field>
      <v-text-field
        ref="email"
        v-model="email"
        :rules="[rules.required, rules.email]"
        :label="$t('EMAIL')"
        outlined
      ></v-text-field>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.addUser {
  h4 {
    padding: 3px;
    font-size: 16px;
    font-weight: 600;
    color: #222f44;
  }
}
</style>
