<script>
import Layout from '@layouts/settings/settings'
import UserManagement from '@components/user-management/user-management'
import { userManagementComputed } from '@state/helpers/userManagement'
import i18n from '@src/i18n'
export default {
  components: { Layout, UserManagement },
  page: {
    title: i18n.t('USER_MANAGEMENT'),
    meta: [{ name: 'description', content: 'User management' }],
  },
  computed: {
    ...userManagementComputed,
  },
}
</script>

<template>
  <Layout :class="!currentModal ? '' : $style.noscroll">
    <div :class="$style.main">
      <h1 :class="$style.headingUsers">{{ $t('USERS') }}</h1>
      <h4>{{ $t('ADMINISTRATORS') }}</h4>
      <UserManagement />
    </div>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';
.noscroll {
  position: fixed;
  overflow: hidden;
}
.main {
  width: 100%;
  margin: 0 0 0 38px;

  h1 {
    display: inline-block;
    margin: 40px 0 32px;
    color: $dark;
  }
  h4 {
    margin-bottom: 10px;
    color: $dark;
  }
}
</style>
