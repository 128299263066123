<script>
import {
  editAdministratorsComputed,
  editAdministratorsMutations,
} from '@state/helpers'
import { userManagementMethods } from '@state/helpers/userManagement'
import {
  emailValidation,
  maxLengthValidation,
  minLengthValidation,
  requiredValidation,
} from '@utils/inputValidation'
import eventBus from '@src/event-bus'
import userStatuses from '@constants/userStatuses'
export default {
  props: {
    bus: {
      type: [String, Object],
      default: 'div',
    },
    resetPassword: {
      type: Function,
      default: () => null,
    },
    resendEmail: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      userStatuses,
      firstName: '',
      lastName: '',
      email: '',
      base64: '',
      photoFileName: '',
      mimeType: '',
      rules: {
        minLength: minLengthValidation,
        maxLength: maxLengthValidation,
        required: requiredValidation,
        email: emailValidation,
      },
    }
  },
  computed: {
    ...editAdministratorsComputed,
    baseApiUrl() {
      return this.storedBaseApiUrl
        ? this.storedBaseApiUrl
        : 'http://localhost:8081'
    },
  },
  beforeMount() {
    this.firstName = this.userDetails.first_name
    this.lastName = this.userDetails.last_name
    this.email = this.userDetails.username
  },
  mounted() {
    this.bus.$on('toggleInactive', this.toggleInactive)
    this.bus.$on('validateForm', this.validateForm)
  },
  beforeDestroy() {
    this.bus.$off('toggleInactive')
    this.bus.$off('validateForm')
  },
  methods: {
    ...editAdministratorsMutations,
    editUser: userManagementMethods.editUser,
    handlePhotoClick() {
      this.$refs.file.click()
    },
    handleFileSelection(event) {
      this.SET_UPDATE_PHOTO(this.userDetails.id)

      const reader = new FileReader()
      const callback = () => {
        this.photoFileName = event.target.files[0].name
        reader.removeEventListener('load', callback)
        const splitMark = reader.result.search(/;/)
        this.base64 = reader.result.slice(splitMark + 8)
        this.mimeType = reader.result.slice(5, splitMark)
      }
      reader.addEventListener('load', callback, false)
      reader.readAsDataURL(event.target.files[0])
    },
    validateForm() {
      // Prevent editing of suspended accounts
      if (this.userDetails.status === userStatuses.suspended) {
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: this.$i18n.t('CANT_EDIT_INACTIVE_ACCOUNTS'),
          type: 'error',
        })
        return
      }
      const invalidFields = []
      Object.keys(this.$refs).forEach((field) => {
        switch (field) {
          case 'file':
            break
          case 'email':
            if (
              this.rules.required(this.$refs.email.value) !== true ||
              this.rules.email(this.$refs.email.value) !== true
            ) {
              invalidFields.push(field)
            }
            break
          default:
            if (
              this.rules.required(this.$refs[field].value) !== true ||
              this.rules.maxLength(this.$refs[field].value) !== true
            ) {
              invalidFields.push(field)
            }
        }
      })
      if (!invalidFields.length) {
        this.submitForm()
      } else {
        invalidFields.forEach((field) => {
          this.$refs[field].error = true // Make invalid inputs jiggle
        })
      }
    },
    submitForm() {
      const payload = {
        status: this.userDetails.status,
        disabled: this.userDetails.status === 'PENDING',
        enterprise_id: this.enterpriseId,
        first_name: this.firstName,
        id: this.userDetails.id,
        last_name: this.lastName,
        username: this.userDetails.username,
        roles: this.userDetails.roles,
      }
      if (this.base64) {
        payload.imageContent = {
          content: this.base64,
          file_name: this.photoFileName,
          mime_type: this.mimeType,
        }
      }
      this.editUser(payload)
    },
    toggleInactive() {
      const payload = {
        status:
          this.userDetails.status === userStatuses.active
            ? userStatuses.suspended
            : userStatuses.active,
        disabled:
          this.userDetails.status === userStatuses.active ? 'true' : false,
        enterprise_id: this.enterpriseId,
        first_name: this.userDetails.first_name,
        id: this.userDetails.id,
        last_name: this.userDetails.last_name,
        username: this.userDetails.username,
        roles: this.userDetails.roles,
      }
      this.editUser(payload)
    },
  },
}
</script>

<template>
  <div class="addUser">
    <div v-if="userDetails.status === userStatuses.active">
      <h4>{{ $t('PROFILE_IMAGE') }}</h4>
      <input
        ref="file"
        accept=".jpg, jpeg, .png"
        type="file"
        style="display: none"
        @change="(files) => handleFileSelection(files)"
      />
      <div class="icon" @click="handlePhotoClick">
        <div
          v-if="base64"
          class="imageDiv"
          :style="{
            backgroundImage: `url(data:${mimeType};base64,${base64})`,
          }"
        ></div>
        <div
          v-else-if="photos[userDetails.id]"
          class="imageDiv"
          :style="{
            backgroundImage: `url(${photos[userDetails.id]})`,
          }"
        ></div>
      </div>
    </div>

    <h4>{{ $t('GENERAL.PERSONAL_DETAILS') }}</h4>
    <v-form>
      <v-text-field
        ref="firstName"
        v-model="firstName"
        :rules="[rules.required, rules.maxLength]"
        outlined
        :disabled="userDetails.status === userStatuses.suspended"
        :label="$t('LABEL_FIRST_NAME')"
      ></v-text-field>
      <v-text-field
        ref="lastName"
        v-model="lastName"
        :rules="[rules.required, rules.maxLength]"
        outlined
        :disabled="userDetails.status === userStatuses.suspended"
        :label="$t('LABEL_LAST_NAME')"
      ></v-text-field>
      <v-text-field
        ref="email"
        v-model="email"
        disabled
        :rules="[rules.required, rules.email]"
        outlined
        :label="$t('EMAIL')"
      ></v-text-field>
    </v-form>
    <v-app-bar
      v-if="userDetails.status === userStatuses.active"
      class="appBar"
      @click="resetPassword"
    >
      <v-btn icon>
        <v-icon color="black" small>lock</v-icon>
      </v-btn>
      <v-toolbar-title class="smallText">
        {{ $t('RESET_PASSWORD') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon large>
        <v-icon color="black">arrow_right</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar
      v-if="userDetails.status === userStatuses.pending"
      class="appBar"
      @click="resendEmail"
    >
      <v-btn icon>
        <v-icon color="black" small>lock</v-icon>
      </v-btn>
      <v-toolbar-title class="smallText">
        {{ $t('RESEND_WELCOME_EMAIL') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon large>
        <v-icon color="black">arrow_right</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.addUser {
  h4 {
    padding: 3px;
    font-size: 16px;
    font-weight: 600;
    color: $dark;
  }
  .icon {
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
    overflow: hidden;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='26' cy='26' r='26' fill='%23ffffff'/%3E%3Cg fill='%238C949E' fill-rule='nonzero' transform='translate(14.444444 14.444444)'%3E%3Ccircle cx='11.5555556' cy='5.7777778' r='5.7777778'/%3E%3Cpath d='M23.1111382 19.0666667c.0048647-1.129442-.6524574-2.156967-1.679916-2.626-3.1140729-1.3550553-6.4797734-2.0353826-9.8756666-1.9962223-3.3958933-.0391603-6.7615938.641167-9.8756667 1.9962223-1.0274586.469033-1.6847807 1.496558-1.679916 2.626v4.0444444h23.1111653v-4.0444444z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50px;
    box-shadow: 0 0 0 0 0 rgba($primary, 0) !important;
    transition: box-shadow 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 2px rgba($primary, 1) !important;
      transition: box-shadow 0.1s ease-in-out;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .smallText {
    padding: 0 !important;
    font-size: 16px;
  }

  .appBar {
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0 20px $user-card-shadow;
  }

  .imageDiv {
    width: 100%;
    height: 100%;
    background-repeat: none;
    background-position: center;
    background-size: cover;
    border-radius: 50px;
  }
}
</style>
