<script>
import AddUserForm from '@components/add-user-form/add-user-form'
import EditUserForm from '@components/edit-user-form/edit-user-form'
import Loader from '@components/custom-loader/custom-loader'
import eventBus from '@src/event-bus'
import {
  userManagementMethods,
  userManagementComputed,
  userManagementMutations,
} from '@state/helpers/userManagement'
import userStatuses from '@constants/userStatuses'
import { getSavedStateSession } from '@utils/storage'
const UserCard = () => import('@components/user-card/user-card')
const AddAdministrator = () =>
  import('@components/add-administrator/add-administrator')
const ModalForm = () => import('@components/_base-modal-form/_base-modal-form')
export default {
  components: { UserCard, AddAdministrator, ModalForm, Loader },
  data() {
    return {
      userStatuses,
      addForm: AddUserForm,
      editForm: EditUserForm,
      bus: eventBus,
      updatedImage: null,
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...userManagementComputed,
    sortedUsers() {
      return this.users.slice().sort((a, b) => {
        return a.first_name.localeCompare(b.first_name)
      })
    },
    btn2Text() {
      return this.userDetails.status === userStatuses.active
        ? this.$i18n.t('MAKE_INACTIVE')
        : this.userDetails.status === userStatuses.suspended
        ? this.$i18n.t('MAKE_ACTIVE')
        : this.$i18n.t('DELETE_USER')
    },
    btn1Visible() {
      return this.userDetails.status !== userStatuses.suspended
    },
    baseApiUrl() {
      return this.storedBaseApiUrl
        ? this.storedBaseApiUrl
        : 'http://localhost:8081'
    },
  },
  watch: {
    enterpriseId(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.getUsers(newValue)
      }
    },
  },
  beforeMount() {
    if (this.enterpriseId) this.getUsers(this.enterpriseId)
  },
  methods: {
    ...userManagementMethods,
    ...userManagementMutations,
    showAddUser() {
      this.SET_CURRENT_MODAL('addUser')
    },
    showEditUser(userId) {
      this.SET_USER_DETAILS(this.users.find((user) => user.id === userId))
      this.SET_CURRENT_MODAL('editUser')
    },
    addUser() {
      this.bus.$emit('validateForm')
    },
    editUserHandler() {
      this.bus.$emit('validateForm')
    },
    makeInactive() {
      this.bus.$emit('toggleInactive')
    },
    resetPassword() {
      this.passwordResetEmail({
        email: this.userDetails.username,
        context: 'passwordReset',
      })
    },
    resendEmail() {
      this.passwordResetEmail({
        email: this.userDetails.username,
        context: 'resendEmail',
      })
    },
    closeModals() {
      this.SET_CURRENT_MODAL(null)
    },
    deleteUserHandler() {
      this.deleteUser({
        uuId: this.userDetails.id,
        enterpriseId: this.enterpriseId,
      })
    },
  },
}
</script>

<template>
  <div>
    <Loader v-if="loading" />
    <v-container v-else :class="$style.container" width="100%" fluid>
      <ModalForm
        v-if="currentModal === 'addUser'"
        :bus="bus"
        :content="addForm"
        :btn1-visible="true"
        :btn-fn="addUser"
        :close-fn="closeModals"
        :title="$t('ADD_ADMINISTRATOR')"
        :btn-text="$t('ADD_ADMINISTRATOR')"
      />
      <ModalForm
        v-if="currentModal === 'editUser'"
        :bus="bus"
        :btn1-visible="btn1Visible"
        :btn2-visible="true"
        :content="editForm"
        :btn-fn="editUserHandler"
        :btn2-fn="
          userDetails.status === 'PENDING' ? deleteUserHandler : makeInactive
        "
        :close-fn="closeModals"
        :reset-password="resetPassword"
        :resend-email="resendEmail"
        :title="$t('EDIT_ADMINISTRATOR_DETAILS')"
        :btn-text="$t('SAVE_CHANGES')"
        :btn2-text="btn2Text"
        :loading="loading"
      />
      <v-row :key="key" no-gutters>
        <UserCard
          v-for="user in sortedUsers"
          :id="user.id"
          :key="user.id"
          :status="user.status"
          :first-name="user.first_name"
          :last-name="user.last_name"
          :email="user.username"
          :photo="photos[user.id]"
          :edit-details="showEditUser"
          :user-statuses="userStatuses"
          :disabled="isReadOnlyUser"
        />
        <AddAdministrator v-if="!isReadOnlyUser" :click-handler="showAddUser" />
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" module>
.container {
  padding: 0;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>
