import { mapState, mapMutations } from 'vuex'

// TODO: Remove when refactored
export const editAdministratorsMutations = {
  ...mapMutations('userManagement', ['SET_UPDATE_PHOTO']),
}

// TODO: Remove when refactored
export const addAdministratorsComputed = {
  ...mapState('enterprise', {
    enterpriseId: (state) => state.currentEnterprise.id,
  }),
}

// TODO: Remove when refactored
export const editAdministratorsComputed = {
  ...mapState('userManagement', {
    userDetails: (state) => state.userDetails,
    photos: (state) => state.photos,
  }),
  ...mapState('enterprise', {
    enterpriseId: (state) => state.currentEnterprise.id,
  }),
  ...mapState('general', {
    storedBaseApiUrl: (state) => state.base_api_url,
  }),
}
